<template>
  <el-dialog title="选择组织" :visible.sync="openDialog" width="350px" class="dialog-vertical">
    <el-form size="medium" :model="_data" :rules="rules" label-position="right" label-width="70px"
      class="small-form" ref="pid">
      <el-form-item label="上级组织：" prop="pid" style="margin-bottom: 0;">
        <el-select v-model="pid" placeholder="请选择上级组织">
          <el-option v-for="item in pid_list" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="cancel('pid')">取消</el-button>
      <el-button size="small" type="primary" @click="submit('pid')">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { PidList } from "../api/parent-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    IDs: {
      type: Array,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      pid_list: [],
      rules: {
        pid: [{ type: "number", message: "请选择上级组织", trigger: "change" }],
      },
      pid: "",
    };
  },
  methods: {
    // 获取上级组织列表
    getPidList() {
      const data = {};
      data.is_show_no_parent = data.is_show_all_brand = 0;
      PidList(data)
        .then((res) => {
          this.pid_list = res.data;
        })
        .catch((err) => {});
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.$emit("addFeeConfig",this.form)
          const data = {};
          data.pid = this.pid;
          data.ids = this.IDs;
          this.updateList();
          this.openDialog = false;
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.getPidList();
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style>
</style>
